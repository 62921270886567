import React from 'react';
import './ScrollingBanner.css'; // Import the corresponding CSS file

const ScrollingBanner = ({ text }) => {
    return (
      <div className="scrolling-banner">
        {/* <div className="scrolling-text">{text}</div>
        <div className="scrolling-text">{text}</div>
        <div className="scrolling-text">{text}</div>
        <div className="scrolling-text">{text}</div> */}
        <h1>Rebranding Announcement.Exciting News! Workhours is Now Trydo Boss.</h1>
      </div>
    );
  };

export default ScrollingBanner;
