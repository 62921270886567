import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Courses from './Courses';
import ScrollingBanner from '../Banner/Banner';

const Course = () => {
  return (
    <>
    <ScrollingBanner text="Rebranding Announcement:Exciting News! Workhours is Now Trydo Boss." />
    <Navbar/>
    <Courses/>
    <Footer/>
    </>
  );
};

export default Course;