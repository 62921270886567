import React from "react";
import AHero from "./AHero/AHero";
import Navbar from "../Navbar/Navbar";
import ScrollingBanner from "../Banner/Banner";

const AboutUs = () => {
  return (
    <div>
      <ScrollingBanner text="Rebranding Announcement:Exciting News! Workhours is Now Trydo Boss." />
      <Navbar />
      <AHero />
    </div>
  );
};

export default AboutUs;
